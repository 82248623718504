.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 300px;
}

/* Styles */
body {
  font-size: 12px;
  line-height: 1.4em;
  min-width: 230px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  background-color:#eeeeee;
  height:100%;
}

.center {
  text-align: center;
}
